import React from 'react';
import { BiBriefcase, BiHomeAlt, BiUser } from 'react-icons/bi';
import { BsClipboardData, BsBriefcase, BsChatSquareText } from 'react-icons/bs';
import { Link } from 'react-scroll';

const Nav = () => {
  const linkProps = {
    activeClass: 'active',
    smooth: true,
    spy: true,
    className: 'cursor-pointer w-[60px] h-[60px] flex items-center justify-center',
  };

  return (
    <nav className='fixed bottom-2 lg:bottom-8 w-full overflow-hidden z-50'>
      <div className='container mx-auto'>
        <div className='w-full bg-black/20 h-[96px] backdrop-blur-2xl rounded-full max-w-[460px] mx-auto px-5 flex justify-between items-center text-2xl text-white/50'>
          <Link to='home' {...linkProps}><BiHomeAlt /></Link>
          <Link to='about' {...linkProps}><BiUser /></Link>
          <Link to='services' {...linkProps}><BsClipboardData /></Link>
          <Link to='work' {...linkProps}><BsBriefcase /></Link>
          <Link to='contact' {...linkProps}><BsChatSquareText /></Link>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
