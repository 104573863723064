import React from 'react';
import { BsArrowUpRight } from 'react-icons/bs'
import { motion } from 'framer-motion'
import { fadeIn } from '../variants'

const services = [
  {
    name: 'Frontend',
    description:
      'ReactJS, @MUI, TailWind, CSS Bootstrap ',
    link: 'learn more',
  },
  {
    name: 'Backend',
    description:
      'NodeJS(ExpressJS Farmework), PHP(Laravel Framework)',
    link: 'learn more',
  },
  {
    name: 'DevOps',
    description:
      'Docker , CI/CD pipeline , AZURE , AWS',
    link: 'learn more',
  }
];
const Services = () => {
  const goToGitHubProfile = () => {
    window.open('https://github.com/oussama-bosri', '_blank');
  };
  return (
    <section className='section' id='services'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>

          <motion.div
            variants={fadeIn('right', 0.5)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 lg-service lg:bg-bottom bg-no-repeat
          mix-blend-lighten mb-12 lg:mb-0'>
            <h2 className='h2 text-accent mb-6'>What I Do.</h2>
            <h3 className='h max-w-[455px] mb-16'>
              I am a Web Developer & DevOps Engineer with over 4 years of experience.
            </h3>
            <button
              onClick={goToGitHubProfile}
              className='btn btn-sm'>
              See my work
            </button>
          </motion.div>
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'>
            {/*  servise list */}
            <div>
              {services.map((service, index) => {
                const { name, description, link } = service;
                return (
                  <div className='border-b border-white/20 h-[146px] mb-[38px] flex' key={index}>
                    <div className='max-w-[476px]'>
                      <h4 className='text-[20px] tracking-wider
                      font-primary font-semibold mb-6'>{name}</h4>
                      <p className='font-secondary leading-tight'>{description}</p>
                    </div>
                    <div className='flex flex-col flex-1 items-end'>
                      <a
                        href='#'
                        className='btn w-9 h-9 mb-[42PX] flex justify-center items-center'>
                        <BsArrowUpRight />
                      </a>
                      <a
                        href='#'
                        className='text-gradient text-sm'>
                        {link}
                      </a>
                    </div>
                  </div>);

              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  )
};

export default Services;
