import React from 'react';
import { motion } from 'framer-motion'
import { fadeIn } from '../variants'
//image
import Imag1 from '../assets/portfolio.png';
import Imag2 from '../assets/msite.png';
import Imag3 from '../assets/portfolio-img1.png';
const Work = () => {
  const goToGitHubProfile = () => {
    window.open('https://github.com/oussama-bosri', '_blank');
  };
  return (
    <section className='section' id='work'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row gap-x-10'>
          <motion.div
            variants={fadeIn('right', 0.2)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0'>
            {/* text */}
            <div>
              <h2 className='h2 leading-tight text-accent'>
                My Latest 
                Work.
              </h2>
              <p className='max-w-sm mb-12'>
                E-signature Platform Using ReactJS
              </p>
              <button
                className='btn btn-sm'
                onClick={goToGitHubProfile}
              >
                View all projects
              </button>
            </div>
            {/* image */}
            <div className='group relative overflow-hidden border-2
           border-white/50 rounded-xl'>
              {/* overlay */}
              <div className='group-hover:bg-black/70 w-full h-full absolute
              z-40 transition-all duration-300'></div>
              {/* img */}
              <img
                className='group-hover:scale-125 translate-all duration-500' src={Imag2} alt="" />
              {/* pertitle */}
              <div className='absolute -bottom-full left-12
              group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-gradient'>Frontend Developer</span>
              </div>
              {/* title */}
              <div className='absolute -bottom-full left-12
              group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'>WebSite</span>
              </div>
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn('left', 0.2)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex flex-col gap-y-10'>
            {/* image */}
            <div className='group relative overflow-hidden border-2
           border-white/50 rounded-xl'>
              {/* overlay */}
              <div className='group-hover:bg-black/70 w-full h-full absolute
              z-40 transition-all duration-300'></div>
              {/* img */}
              <img
                className='group-hover:scale-125 translate-all duration-500'
                src={Imag1}
                alt=""
              />
              {/* pertitle */}
              <div className='absolute -bottom-full left-12
              group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-gradient'>Frontend Developer</span>
              </div>
              {/* title */}
              <div className='absolute -bottom-full left-12
              group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'>My Portfolio</span>
              </div>
            </div>
            <div className='group relative overflow-hidden border-2
           border-white/50 rounded-xl'>
              {/* overlay */}
              <div className='group-hover:bg-black/70 w-full h-full absolute
              z-40 transition-all duration-300'></div>
              {/* img */}
              <img
                className='group-hover:scale-125 translate-all duration-500' src={Imag3} alt="" />
              {/* pertitle */}
              <div className='absolute -bottom-full left-12
              group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-gradient'>Frontend Developer</span>
              </div>
              {/* title */}
              <div className='absolute -bottom-full left-12
              group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'>Project Title</span>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>

  );
};

export default Work;
