import React from 'react';
//countup 
import CountUp from 'react-countup/';
//intersection observer hook
import { useInView } from 'react-intersection-observer';
//motion
import { motion } from 'framer-motion';
//variant
import { fadeIn } from '../variants';
const About = () => {
  const goToLinkidinProfile = () => {
    window.open('https://www.linkedin.com/in/bosri-oussama/', '_blank');
  };
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  return (
    <section className='section' id='about' ref={ref}>
      <div className='container mx-auto'>
        <div>
          {/* img */}

          {/* text */}
          <div>
            <h1>About me:</h1>
            <p>Computer Engineering Bachelor’s @ Ibn Khaldoun University - 2016</p>
            <p> Computer Science Master’s @ Ibn Khaldoun University - 2018</p>
            <p>I work as a software engineer since 2020:</p>
            <p>- Fullstack Developer </p>
            <p>- DevOps & Cloud</p>
            <p>- System Administrator</p>
            {/* stats */}
            <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
              <div>
                <div className='text-[40px] font-tertiary text-gradient
                mb-2'>
                  {inView ? <CountUp start={0} end={4} duration={3} /> :
                    null}+
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Years of <br />
                  Experience
                </div>
              </div>
              <div>
                <div className='text-[40px] font-tertiary text-gradient
                mb-2'>
                  {inView ? <CountUp start={0} end={10} duration={3} /> :
                    null}
                  +
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Projects <br />
                  Completed
                </div>
              </div>
              <div>
{/*                 <div className='text-[40px] font-tertiary text-gradient
                 mb-2'>
                  {inView ? <CountUp start={0} end={10} duration={3} /> :
                    null}
                  +
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Satisfied <br />
                  Clients
                </div> */}
              </div>
            </div>
            <div>
              <div>
                <button
                  onClick={goToLinkidinProfile}
                  className='btn btn-lg'>
                  Contact me.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
